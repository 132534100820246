export var mixinMtoArray = {

  methods: {

    async ver() {       
      // actualizo estado
      this.$store.dispatch(this.stName + '/update_store', { record:this.accionRow, estado:'ver'});
         
      // paso record a controles
      await this.record2ctrl(this.accionRow, this.schema.ctrls);

      // fn que ejecuta al cargar el record del Mto
      this.record_cargado(); 
    },


    // nueva linea detalle vacía y estado a 'nuevo'
    async nuevo() {
      // actualizo estado   
      this.$store.dispatch(this.stName + '/update_store', { record:{}, estado:'nuevo'});   
     
      // inicializo controles
      await this.record2ctrl({}, this.schema.ctrls, true);   
      this.schema.ctrls["id"].value = new Date().getTime();

      // fn que ejecuta al cargar el record del Mto
      this.record_cargado(); 
    },


    //
    async editar() {
      // actualizo estado
      this.$store.dispatch(this.stName + '/update_store', { record:this.accionRow, estado:'editar'});
     
      // paso record a controles
      await this.record2ctrl(this.accionRow, this.schema.ctrls);

      // fn que ejecuta al cargar el record del Mto
      this.record_cargado(); 
    },


    //
    borrar() {
      var self = this;

      this.$root.$alert
        .open("¿Desea eliminar la linea?", "confirm")
        .then(async function(r) {
          if (!r) return;

          // elimino linea   
          self.$store.commit(self.padre + '/update_record', { opcion: 'borrar', id:self.accionRow.id })

          // actualizo estado e indico que los records del finder han sido modificados
          self.$store.commit(self.stName + '/data2State', { prop:'estado', value:'ver'});
          self.$store.commit(self.padre + '/data2State', { prop:'hijoChanges', value:true});
          
          // emito evento indicando que hay un cambio en Detalle         
          self.$emit('onEvent', { accion:'detalle_modificado', records:self.$store.state[self.padre].records });
        });
    },
    
    
    // gancho antes de guardar nuevo
    guardar_before() { return true },

    // guardo datos en la linea seleccionada
    guardar() {          
      // valido ctrls del schema
      if (!this.validate_fields(this.schema.ctrls)) {
        this.$root.$alert.open("Error de validación de campos", "error");
        return; 
      }
     
      // valido reglas particulares
      if (!this.validate_particular(this.schema.ctrls)) return;
      
      //
      // gancho antes de guardar
      if (!this.guardar_before()) return;

      // 
      //
      // preaparo gancho para guardar_nuevo after
      let guardar_nuevo=this.estado=='nuevo'?true:false;
      //



      var record= this.ctrl2linea(this.schema.ctrls, this.$store.state[this.stName].record);
    
      // actualizo record
      this.$store.commit(this.padre + '/update_record', { opcion: 'guardar', record: record, id:this.accionRow.id })

      // actualizo estado e indico que los records del finder han sido modificados
      this.$store.dispatch(this.stName + '/update_store', { record:record, estado:'ver'});    
      this.$store.commit(this.padre + '/data2State', { prop:'hijoChanges', value:true});  

      // emito evento indicando que hay un cambio en Detalle
      this.$emit('onEvent', { accion:'detalle_modificado', records:this.$store.state[this.padre].records });
      if (guardar_nuevo) this.guardar_nuevo_after(record);
    },

    guardar_nuevo_after(record){
      return record;
    },
    // 
    async cancelar() {
      if (this.estado== 'nuevo') {          
        this.$store.state[this.padre].records.shift();
        this.record2ctrl({}, this.schema.ctrls, true);
        
      }else {
        this.record2ctrl(this.accionRow, this.schema.ctrls);
      }      
      
      // actualizo estado
      this.$store.commit(this.stName + '/data2State', { prop:'estado', value:'ver'});
    },

    
    //
    get_row(id){
      return this.$store.state[this.padre].records.findIndex(elem => elem.id === id);
    }
    

  },
  
};
